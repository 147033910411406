/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import './flash';
import './utils/help';
import './config';
import './mobile-check';

import Vue from 'vue';
import Validator from './plugins/validator';
import AddScriptPlugin from './plugins/addScripts';
import DatatablesButtons from './plugins/datatablesButtons';
import VueMask from 'v-mask';
import ProfileType from './enums/ProfileType';

window.DEBUG = false;

 /**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import Header from './components/HeaderComponent';
import Side from './components/SideComponent';
import Footer from './components/FooterComponent';
import Cover from './components/CoverComponent';

/*	PAGES	*/
import Login from './components/LoginComponent';
import ForgotPassword from './components/passwords/ForgotPasswordComponent';
import ResetPassword from './components/passwords/ResetPasswordComponent';

import Menu from './components/menus/MenuComponent';
import MenuCreate from './components/menus/MenuCreateComponent';
import MenuEdit from './components/menus/MenuEditComponent';
import Resource from './components/resources/ResourceComponent';
import ResourceCreate from './components/resources/ResourceCreateComponent';
import ResourceEdit from './components/resources/ResourceEditComponent';

import DashboardManager from './components/dashboards/DashboardManagerComponent';
import DashboardDirector from './components/dashboards/DashboardDirectorComponent';
import DashboardTeacher from './components/dashboards/DashboardTeacherComponent';
import DashboardResponsible from './components/dashboards/DashboardResponsibleComponent';

import Avatar from './components/avatars/AvatarComponent';
import Pet from './components/pets/PetComponent';
import Game from './components/games/GameComponent';
import GameCreate from './components/games/GameCreateComponent';
import GameEdit from './components/games/GameEditComponent';
import Animation from './components/animations/AnimationComponent';
import AnimationCreate from './components/animations/AnimationCreateComponent';
import AnimationEdit from './components/animations/AnimationEditComponent';
import Song from './components/songs/SongComponent';
import SongCreate from './components/songs/SongCreateComponent';
import SongEdit from './components/songs/SongEditComponent';
import Book from './components/books/BookComponent';
import BookCreate from './components/books/BookCreateComponent';
import BookEdit from './components/books/BookEditComponent';

import School from './components/schools/SchoolComponent';
import SchoolCreate from './components/schools/SchoolCreateComponent';
import SchoolEdit from './components/schools/SchoolEditComponent';

import Classroom from './components/classrooms/ClassroomComponent';
import ClassroomCreate from './components/classrooms/ClassroomCreateComponent';
import ClassroomEdit from './components/classrooms/ClassroomEditComponent';
import ClassroomSettings from './components/classrooms/ClassroomSettingsComponent';

import Manager from './components/managers/ManagerComponent';
import ManagerCreate from './components/managers/ManagerCreateComponent';
import ManagerEdit from './components/managers/ManagerEditComponent';
import Responsible from './components/responsibles/ResponsibleComponent';
import ResponsibleCreate from './components/responsibles/ResponsibleCreateComponent';
import ResponsibleEdit from './components/responsibles/ResponsibleEditComponent';
import ResponsibleSettings from './components/responsibles/ResponsibleSettingsComponent';
import Director from './components/directors/DirectorComponent';
import DirectorCreate from './components/directors/DirectorCreateComponent';
import DirectorEdit from './components/directors/DirectorEditComponent';
import Teacher from './components/teachers/TeacherComponent';
import TeacherCreate from './components/teachers/TeacherCreateComponent';
import TeacherEdit from './components/teachers/TeacherEditComponent';
import Student from './components/students/StudentComponent';
import StudentCreate from './components/students/StudentCreateComponent';
import StudentEdit from './components/students/StudentEditComponent';
import StudentPassword from './components/students/StudentPasswordComponent';
import StudentQrcode from './components/students/StudentQrcodeComponent';

import Tag from './components/tags/TagComponent';
import TagCreate from './components/tags/TagCreateComponent';
import TagEdit from './components/tags/TagEditComponent';

import Profile from './components/profiles/ProfileComponent';
import ProfileCreate from './components/profiles/ProfileCreateComponent';
import ProfileEdit from './components/profiles/ProfileEditComponent';

import Celebration from './components/celebrations/CelebrationComponent';
import CelebrationCreate from './components/celebrations/CelebrationCreateComponent';
import CelebrationEdit from './components/celebrations/CelebrationEditComponent';
import CelebrationSetting from './components/celebrations/CelebrationSettingComponent';

import Ticket from './components/tickets/TicketComponent';
import TicketCreate from './components/tickets/TicketCreateComponent';

import Module from './components/modules/ModuleComponent';
import ModuleCreate from './components/modules/ModuleCreateComponent';
import ModuleEdit from './components/modules/ModuleEditComponent';

import Faq from './components/faqs/FaqComponent';
import FaqCreate from './components/faqs/FaqCreateComponent';
import FaqEdit from './components/faqs/FaqEditComponent';

import SchoolReport from './components/schools/SchoolReportComponent';
import ClassroomReport from './components/classrooms/ClassroomReportComponent';
import TeacherReport from './components/teachers/TeacherReportComponent';
import StudentReport from './components/students/StudentReportComponent';
import StudentReportDetails from './components/students/StudentReportDetailsComponent';
import ModuleReport from './components/modules/ModuleReportComponent';
import ActivityReport from './components/activities/ActivityReportComponent';
import IndicationReport from './components/indications/IndicationReportComponent';
import IndicationReportDetails from './components/indications/IndicationReportDetailsComponent';
import IndicationCreate from './components/indications/IndicationCreateComponent';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Validator);
Vue.use(AddScriptPlugin);
Vue.use(DatatablesButtons);
Vue.use(VueMask);

PROFILE_CODE = ProfileType.getByName(PROFILE_CODE);

const app = new Vue({
    el: '#app',
    components: {
        'app-header': Header,
    	'app-side': Side,
    	'app-footer': Footer,
        'app-cover': Cover,

        'app-login': Login,
        'app-forgot-password': ForgotPassword,
        'app-reset-password': ResetPassword,

        'app-menu': Menu,
        'app-menu-create': MenuCreate,
        'app-menu-edit': MenuEdit,
        'app-resource': Resource,
        'app-resource-create': ResourceCreate,
        'app-resource-edit': ResourceEdit,

        'app-dashboard-manager': DashboardManager,
        'app-dashboard-director': DashboardDirector,
        'app-dashboard-teacher': DashboardTeacher,
        'app-dashboard-responsible': DashboardResponsible,

        'app-profile': Profile,
        'app-profile-create': ProfileCreate,
    	'app-profile-edit': ProfileEdit,
        
        'app-avatar': Avatar,
        'app-pet': Pet,

        'app-game': Game,
        'app-game-create': GameCreate,
        'app-game-edit': GameEdit,

        'app-animation': Animation,
        'app-animation-create': AnimationCreate,
        'app-animation-edit': AnimationEdit,

        'app-song': Song,
        'app-song-create': SongCreate,
        'app-song-edit': SongEdit,

        'app-book': Book,
        'app-book-create': BookCreate,
        'app-book-edit': BookEdit,

        'app-school': School,
        'app-school-create': SchoolCreate,
        'app-school-edit': SchoolEdit,

        'app-classroom': Classroom,
        'app-classroom-create': ClassroomCreate,
    	'app-classroom-edit': ClassroomEdit,
        'app-classroom-settings': ClassroomSettings,

        'app-manager': Manager,
        'app-manager-create': ManagerCreate,
        'app-manager-edit': ManagerEdit,

        'app-responsible': Responsible,
        'app-responsible-create': ResponsibleCreate,
        'app-responsible-edit': ResponsibleEdit,
        'app-responsible-settings': ResponsibleSettings,

        'app-director': Director,
        'app-director-create': DirectorCreate,
        'app-director-edit': DirectorEdit,

        'app-teacher': Teacher,
        'app-teacher-create': TeacherCreate,
        'app-teacher-edit': TeacherEdit,

        'app-student': Student,
        'app-student-create': StudentCreate,
    	'app-student-edit': StudentEdit,
        'app-student-password': StudentPassword,
        'app-student-qrcode': StudentQrcode,

        'app-tag': Tag,
        'app-tag-create': TagCreate,
        'app-tag-edit': TagEdit,

        'app-celebration': Celebration,
        'app-celebration-create': CelebrationCreate,
    	'app-celebration-edit': CelebrationEdit,
        'app-celebration-setting': CelebrationSetting,

        'app-ticket': Ticket,
        'app-ticket-create': TicketCreate,

        'app-module': Module,
        'app-module-create': ModuleCreate,
    	'app-module-edit': ModuleEdit,

        'app-faq': Faq,
        'app-faq-create': FaqCreate,
        'app-faq-edit': FaqEdit,

        'app-school-report': SchoolReport,
        'app-classroom-report': ClassroomReport,
        'app-teacher-report': TeacherReport,
        'app-student-report': StudentReport,
        'app-student-report-details': StudentReportDetails,
        'app-module-report': ModuleReport,
        'app-activity-report': ActivityReport,
        'app-indication-report': IndicationReport,
        'app-indication-report-details': IndicationReportDetails,
        'app-indication-create': IndicationCreate,
    }
});
