<template>
	<div v-if="student">
		<app-page-header
			icon="fa fa-user"
			:title="'Editar Aluno: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/students', title: 'Alunos' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-select
									name="school_id"
									label="Escola"
									title="Escola"
									empty="false"
									:required="true"
									:options="schools"
									:data-live-search="true"
									:callback="loadClassrooms"
									:value="(student.data ? student.data.school_id : '')"
								></app-select>
								<app-select
									name="classroom_id"
									label="Sala"
									title="Sala"
									empty="false"
									:required="true"
									:options="classrooms"
									:data-live-search="true"
									:value="(student.data ? student.data.classroom_id : '')"
								></app-select>
								<app-select
									v-if="student.responsible"
									name="responsible_id"
									label="Responsável"
									title="Responsável"
									empty="false"
									:required="true"
									:options="responsibles"
									:data-live-search="true"
									:value="student.responsible.id"
								></app-select>
								<app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
									:value="student.name"
                                ></app-input>
								<app-input
                                    name="ra"
                                    label="RA"
                                    placeholder="RA"
                                    :maxLength="15"
									:value="student.ra"
                                ></app-input>
								<app-input
									title="apenas letras e números, mínimo 4 caracteres"
                                    name="user[login]"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="50"
                                    :minLength="4"
									:disabled="true"
									pattern="[A-Za-z0-9]{4,12}"
                                    :value="student.user.login"
                                ></app-input>
								<app-input
                                    name="user[password]"
                                    label="Senha"
                                    placeholder="Senha"
                                    :required="true"
                                    :readonly="true"
									:value="pass"
                                ></app-input>
								<app-toggle
									label="Status"
									name="active"
									:value="student.active"
								></app-toggle>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                    <app-link
										type="info"
										icon="key"
										title="Gerar nova senha"
										:onClick="generateNewPass"
										:loading="this.newPassLoading"
										:disabled="this.newPassLoading"
									></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

	export default {
		components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
		data() {
            return {
                student: null,
                pass: false,
                schools: [],
				classrooms: [],
				responsibles: [],
				newPassLoading: false,
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
		mounted() {
            this.load(this.id);
        },
		methods: {
			load(id) {
				axios.get(`/web/students/${id}`)
                    .then(res => {
                        this.student = res.data;
						this.pass = res.data.pass;
						
						if (this.student.responsible) {
                            this.loadResponsibles();
                        } else {
							this.loadSchools();
						}
                    })
                    .catch(err => {});
			},
			loadSchools() {
				axios.get('/web/schools/list?toTags=true')
					.then(res => {
						this.schools = res.data;
						this.loadClassrooms(this.student.data.school_id);
					})
					.catch(err => {});
			},
			loadClassrooms(school_id) {
				axios.get(`/web/classrooms/findBySchool/${school_id}?toTags=true`)
					.then(res => {
						this.classrooms = res.data;
					})
					.catch(err => {});
			},
			loadResponsibles() {
				axios.get('/web/responsibles/list')
					.then(res => {
						this.responsibles = res.data;
					})
					.catch(err => {});
			},
			generateNewPass() {
				this.newPassLoading = true;

				axios.get('/web/students/generatePassword')
					.then(res => {
						this.pass = res.data;
						this.newPassLoading = false;
					})
					.catch(err => {});
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/students/${this.student.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/students';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>