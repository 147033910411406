<template>
    <div v-if="teacher">
        <app-page-header
			icon="fa fa-user"
			:title="'Editar Professor: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/teachers', title: 'Professores' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row" v-if="profileCode != 'director'">
                                <div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb"
                                        class="thumb img-thumbnail img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :withTitle="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                    <input
                                        type="hidden"
                                        name="removeThumb"
                                        :value="removeThumb"
                                    >
                                </div>
                                <app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
                                    :value="teacher.name"
                                ></app-input>
                                <app-input
                                    name="user[email]"
                                    label="E-mail"
                                    placeholder="E-mail"
                                    :maxLength="300"
                                    :required="true"
                                    type="email"
                                    :value="teacher.user.email"
                                ></app-input>
                                <app-input
                                    name="rm"
                                    label="RM"
                                    placeholder="RM"
                                    :maxLength="10"
                                    :required="true"
                                    :value="teacher.rm"
                                ></app-input>
                                <app-input
                                    name="user[login]"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="11"
                                    :minLength="4"
                                    title="apenas letras e números, mínimo 4 caracteres"
                                    :disabled="true"
                                    :value="teacher.user.login"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="phone"
                                    label="Telefone"
                                    placeholder="Telefone"
                                    mask="(##) ####-####"
                                    :masked="true"
                                    :maxLength="15"
                                    :minLength="14"
                                    :required="true"
                                    :value="teacher.phone"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="cell_phone"
                                    label="Celular"
                                    placeholder="Celular"
                                    mask="(##) #####-####"
                                    :maxLength="15"
                                    :minLength="14"
                                    :value="teacher.cell_phone"
                                ></app-input>
								<app-toggle
                                    v-if="profileCode == 'manager'"
									label="Status"
									name="active"
									:value="teacher.active"
								></app-toggle>
                            </div>
                            <div class="row">
                                <app-input-tags
                                    ref="schools"
                                    name="schools"
                                    label="Escolas"
                                    urlData="/web/schools/list?toTags=true"
									title="Selecione pelo menos uma escola"
									:fields="{ value: 'value', text: 'label' }"
                                    :required="true"
                                    :callback="onSelectedSchools"
                                    :values="teacher.schools"
                                ></app-input-tags>
                                <div class="col-12">
                                    <fieldset>
                                        <legend class="h6">Salas</legend>
                                        <nav>
                                            <div class="nav nav-tabs" role="tablist">
                                                <a
                                                    v-for="(s, index) in this.schools"
                                                    class="nav-item nav-link btn-ouline-secondary"
                                                    :class="{ 'active' : (index == 0) }"
                                                    :id="'nav-' + s.id + '-tab'"
                                                    data-toggle="tab"
                                                    :href="'#nav-' + s.id"
                                                    role="tab"
                                                    :aria-controls="'nav-' + s.id"
                                                >
                                                    {{ s.name }}
                                                </a>
                                            </div>
                                        </nav>
                                        <div class="tab-content">
                                            <span
                                                v-if="!schools.length"
                                                class="my-3 text-center text-muted w-100 d-block"
                                            >
                                                Selecione pelo menos uma escola
                                            </span>
                                            <div
                                                v-for="(s, index) in this.schools"
                                                class="tab-pane fade"
                                                :class="{ 'active show' : (index == 0) }"
                                                :id="'nav-' + s.id"
                                                role="tabpanel"
                                                :aria-labelledby="'nav-' + s.id + '-tab'"
                                            >
                                                <div class="row p-2">
                                                    <div
                                                        v-for="classroom in s.classrooms"
                                                        class="col-12 col-xl-3 col-md-6"
                                                    >
                                                        <div class="animated-checkbox">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="classrooms[][id]"
                                                                    :value="classroom.id"
                                                                    :checked="classroom.checked"
                                                                >
                                                                <span
                                                                    class="label-text"
                                                                    :title="classroom.name"
                                                                >
                                                                    {{ classroom.name }}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="w-100 border-bottom my-3"></div>
                                                    <div class="col-12">
                                                        <div class="animated-checkbox">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="s.checked"
                                                                    @change="selectAllClassrooms($event, s.checked)"
                                                                >
                                                                <span class="label-text">
                                                                    Selecionar Todas
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div v-if="changePass" class="col-12">
                                    <fieldset>
                                        <legend class="h6">Editar Senha</legend>
                                        <div class="row">
                                            <app-input
                                                name="user[password]"
                                                label="Senha"
                                                placeholder="Senha"
                                                :maxLength="10"
                                                :minLength="6"
                                                :required="true"
                                                :disabled="!changePass"
                                                title="mínimo 6 caracters"
                                                type="password"
                                            ></app-input>
                                            <app-input
                                                name="confirm_password"
                                                label="Confirmar Senha"
                                                placeholder="Confirmar Senha"
                                                :maxLength="10"
                                                :minLength="6"
                                                :required="true"
                                                :disabled="!changePass"
                                                title="mínimo 6 caracters"
                                                type="password"
                                            ></app-input>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                    <app-link
                                        v-if="profileCode != 'director'"
                                        type="edit"
                                        icon="pencil-square-o"
                                        title="Editar Senha"
                                        :onClick="() => { changePass = !changePass }"
                                    ></app-link>
                                </div>
                            </div>
                        </form>

                        <app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';
	import InputTags from '../forms/InputTagsComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
			'app-input-tags': InputTags,
            'app-toggle': Toggle,
        },
        data() {
            return {
                teacher: null,
                DEFAULT_IMAGE: '/assets/img/default_thumbs/user.png',
                tempImage: '',
				removeThumb: 0,
                changePass: false,
                schools: [],
            }
        },
        props: {
            id: String,
            backUrl: String,
            profileCode: String,
        },
        mounted() {
            this.tempImage = this.DEFAULT_IMAGE;
            this.load(this.id);
        },
        methods: {
            load(id) {
                axios.get(`/web/teachers/${id}`)
                    .then(res => {
                        this.teacher = res.data;
                        this.tempImage = this.teacher.thumb;
                    })
                    .catch(err => {});
            },
            openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                this.removeThumb = (image != null) ? 0 : 1;

                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
            onSelectedSchools(schools) {
                this.schools = [];
                let schoolIDs = schools.map(s => { return s.value; });

                for (let school of schools) {
                    this.schools.push({
                        id: school.value,
                        name: school.label,
                        checked: false,
                        classrooms: [],
                    });
                }

                if (schoolIDs.length) {
                    this.loadClassrooms(schoolIDs);
                }
            },
            loadClassrooms(schoolIDs) {
                axios.get(`/web/classrooms/findBySchools/${schoolIDs}?agrouped=true`)
                    .then(res => {
                        for (let school of this.schools) {
                            for (let k in res.data) {
                                if (school.id == k) {
                                    school.classrooms = res.data[k].map((classroom) => {
                                        let checked = this.teacher
                                            .classrooms
                                            .some(c => {
                                                return (c.id == classroom.id);
                                            });
                                        classroom.checked = checked;

                                        return classroom;
                                    });
                                }
                            }
                        }
                    })
                    .catch(err => {});
            },
            selectAllClassrooms(e, value) {
                cash(e.target)
                    .closest('.row')
                    .find('input[name="classrooms[][id]"]')
                    .each((i, el) => {
                        cash(el).prop('checked', value);
                    });
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');
                    formData.append('thumb', this.$refs.imageSelect.file);

					for (let tag of this.$refs.schools.selectedData) {
						formData.append('schools[][id]', tag.value);
					}

                    axios.post(`/web/teachers/${this.teacher.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                if (this.isManager) {
                                    window.location.href = '/teachers';
                                } else {
                                    window.location.href = '/';
                                }
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>
