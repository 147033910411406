<template>
	<div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-th-list"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
					<div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Nome</strong></p>
									<p>{{ data.name }}</p>
								</div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Descrição</strong></p>
                                    <p>{{ data.description }}</p>
                                </div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Ano</strong></p>
									<p>{{ data.year.title }}</p>
								</div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Status</strong></p>
									<p>{{ data.active_text }}</p>
								</div>
								<div class="col-12">
									<fieldset>
										<legend class="h6">Atividades</legend>
										<div class="list-group">
											<a
												v-for="activity in data.activities"
												:href="'/'
													+ activity.activity_type.code
													+ 's?id='
													+ activity.type.id
												"
												:class="[
													'list-group-item list-group-item-action',
													'd-flex align-items-center',
													'justify-content-between'
												]"
											>
												{{ activity.title_full }}
												<span
													:class="[
														'badge badge-pill badge-info',
														'float-right p-2'
													]"
												>
													<i class="fa fa-eye"></i>
												</span>
											</a>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				data: {}
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Módulo' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/modules/${id}`)
					.then(res => {
						this.loading = false;
						this.data = res.data;
						this.title = `${this.defaultTitle}: ${this.data.id}`;
					})
					.catch(err => {});
			}
		}
	}
</script>
